import Vue from 'vue'
import axios from 'axios'
import {
  baseURL,
  contentType,
  debounce,
  failureCode,
  invalidCode,
  noPermissionCode,
  requestTimeout,
  successCode,
  tokenName
} from '@/config'
import store from '@/store'
import qs from 'qs'
import router from '@/router'

let loadingInstance

/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
  console.log('code', code)
  switch (code) {
    case failureCode:
      Vue.prototype.$notify.error({
        title: '错误提示',
        message: msg,
        duration: 0,
        offset: 100,
      });
      break
    case invalidCode:
      const currentUrl = window.location.href;
      if (currentUrl.includes('MallHomepage')) {

      } else if (currentUrl.includes('BrandIndex')) {

      } else {
        console.log('长时间未操作，登陆已失效，请重新选择品牌登陆！')

        Vue.prototype.$notify.error({
          title: '登陆失效',
          message: '长时间未操作，登陆已失效，请重新选择品牌登陆！', offset: 150,
       
        });
        // window.open(this.$locationViewUrl+"#/MallHomepage", "_blank");
        router.push('/MallHomepage')
      }


      break
    case noPermissionCode:
      Vue.prototype.$notify.error({
        title: '错误提示',
        message: msg,
        duration: 0,
        offset: 100,
      });
      break
    case 500:
      Vue.prototype.$notify.error({
        title: '错误提示',
        message: msg,
        duration: 0,
        offset: 100,
      });
      break
    case 999:
      Vue.prototype.$notify.error({
        title: '错误提示',
        message: msg,
        duration: 0,
        offset: 100,
      });
      break
    default:
      Vue.prototype.$notify.error({
        title: '错误提示',
        message: msg,
        duration: 0,
        offset: 100,
      });
      break
  }
}


const instance = axios.create({
  baseURL:'https://'+window.location.hostname+'/api',
  // baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken']) {
      config.headers[tokenName] = store.getters['user/accessToken']
    }
    //这里会过滤所有为空、0、false的key，如果不需要请自行注释
    // if (config.data) {
    //   config.data = Vue.prototype.$baseLodash.pickBy(config.data, Vue.prototype.$baseLodash.identity)
    // }
    if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
      config.data = qs.stringify(config.data)
    }
    if (debounce.some((item) => config.method.includes(item))) {
      loadingInstance = Vue.prototype.$baseLoading()
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.close()
    }
    const {
      data,
      config
    } = response
    const {
      code,
      msg
    } = data
    let contentType = response.headers['content-type']
    // 是否操作正常
    if (successCode == code) {
      return data
    } else if (contentType.indexOf('application/vnd.ms-excel') != -1) {
      let content = response.headers['content-disposition'].split('=')
      return {
        filename: content[1],
        data: response.data,
      }
    } else {
      handleCode(code, msg)
      // return Promise.reject('请求异常拦截:' + JSON.stringify({ url: config.url, code, msg }) || 'Error')
      return data
    }
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close()
    }
    const {
      response,
      message
    } = error
    if (error.response && error.response.data) {
      const {
        status,
        data
      } = response
      handleCode(status, data.msg || message)
      return Promise.reject(error)
    } else {
      let {
        message
      } = error
      if (message === 'Network Error') {
        message = '连接异常'
      }
      if (message.includes('timeout')) {
        message = '请求超时'
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '请求异常' + code
      }
      Vue.prototype.$baseMessage(message || `请求未知异常`, 'error')
      return Promise.reject(error)
    }
  }
)

export default instance

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/BrandIndex',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        children: [
            {
                path: '/BrandIndex',
                name: 'BrandIndex',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/Index.vue'),
            },
            {
                path: '/MallHomepage',
                name: 'MallHomepage',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/indexTwo.vue'),
            },

            {
                path: '/login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
            },
            {
                path: '/forget_password',
                name: 'ForgetPassword',
                component: () => import(/* webpackChunkName: "forget_password" */ '../views/ForgetPassword.vue'),
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
            },
            {
                path: '/index',
                name: 'Index',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/Index.vue'),
            },


            {
                path: '/MallHomepage',
                name: 'MallHomepage',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/indexTwo.vue'),
            },
            {
                path: '/IndexThree',
                name: 'IndexThree',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/indexThree.vue'),
                children: [
                    {
                        path: '/goods',
                        name: 'Goods',
                        redirect: '/goods/goodsList',
                        component: () => import(/* webpackChunkName: "goods" */ '../views/main/goods/Index.vue'),
                        children: [
                            {
                                path: '/goods/GoodsHome',
                                name: 'GoodsHome',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/home.vue'),
                            },
                            {
                                path: '/goods/news',
                                name: 'GoodNews',
                                component: () => import(/* webpackChunkName: "member" */ '../views/main/goods/news/Index.vue'),
                                
                            },
                            {
                                path: '/goods/NewDetail',
                                name: 'GoodNewDetail',
                                component: () => import(/* webpackChunkName: "index" */ '../views/main/goods/news/Detail.vue'),
                            },
                            {
                                path: '/goods/Introduce',
                                name: 'GoodIntroduce',
                                component: () => import(/* webpackChunkName: "index" */ '../views/main/Introduce/index.vue'),
                            },
                            {
                                path: '/goods/ChangePwd',
                                name: 'ChangePwd',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/personalCenter/index.vue'),
                            },
                            {
                                path: '/goods/goodsList',
                                name: 'GoodShop',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/Goods.vue'),
                            },
                            {
                                path: '/goods/goodsCart',
                                name: 'GoodsCart',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodCart.vue'),
                            },
                            {
                                path: '/goods/goodsCartDB',
                                name: 'goodsCartDB',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodCartDB.vue'),
                            },
                            {
                                path: '/goods/goodCartOrder',
                                name: 'GoodCartOrder',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodCartOrder.vue'),
                            },

                            {
                                path: '/goods/goodOrderDetail',
                                name: 'goodOrderDetail',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodOrderDetail.vue'),
                            },
                            {
                                path: '/goods/goods_purchase',
                                name: 'GoodsPurchase',
                                component: () => import(/* webpackChunkName: "goods_purchase" */ '../views/main/goods/GoodsPurchase.vue'),
                            },
                            {
                                path: '/goods/goods_history',
                                name: 'GoodsHistory',
                                component: () => import(/* webpackChunkName: "goods_history" */ '../views/main/goods/GoodsHistory.vue'),
                            },
                            {
                                path: '/goods/Detail',
                                name: 'GoodsDetail',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/Detail.vue'),
                            },
                            {
                                path: '/goods/DetailNew',
                                name: 'GoodsDetailNew',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/DetailNew.vue'),
                            },
                            {
                                path: '/goods/DeatilProductingCommodity',
                                name: 'GoodsDeatilProductingCommodity',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/DeatilProductingCommodity.vue'),
                            },
                            {
                                path: '/goods/DeatilXxd',
                                name: 'DeatilXxd',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/DeatilXxd.vue'),
                            },
                            {
                                path: '/goods/DeatilTransportCommodity',
                                name: 'GoodsDeatilTransportCommodity',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/DeatilTransportCommodity.vue'),
                            },
                            {
                                path: '/goods/DetailTui',
                                name: 'GoodsDetailTui',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/DetailTui.vue'),
                            },
                            {
                                path: '/goods/DetailFD',
                                name: 'GoodsDetailFD',
                                component: () => import(/* webpackChunkName: "goods_detail" */ '../views/main/goods/DetailFD.vue'),
                            },

                            {
                                path: '/goods/cartItemDetail',
                                name: 'cartItemDetail',
                                component: () => import(/* webpackChunkName: "goods_cartItemDetail" */ '../views/main/goods/cartItemDetail.vue'),
                            },
                            {
                                path: '/goods/goodOrder',
                                name: 'GoodOrder',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodOrder.vue'),
                            },
                            {
                                path: '/goods/goodOrderDB',
                                name: 'goodOrderDB',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodOrderDB.vue'),
                            },
                            {
                                path: '/goods/imageGoodList',
                                name: 'ImageGoodList',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/imageGoodList.vue'),
                            },
                            {
                                path: '/goods/coCreationCenter',
                                name: 'CoCreationCenter',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/coCreationCenter.vue'),
                            },
                            {
                                path: '/goods/GoodFan',
                                name: 'GoodFan',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/GoodFan.vue'),
                            },
                            {
                                path: '/goods/imageGoodListDetail',
                                name: 'ImageGoodListDetail',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/imageGoodListDetail.vue'),
                            },
                            {
                                path: '/goods/imageGoodListDetails',
                                name: 'ImageGoodListDetailS',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/imageGoodListDetails.vue'),
                            },
                            {
                                path: '/goods/newGoodViewCounter',
                                name: 'newGoodViewCounter',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/newGoodViewCounter.vue'),
                            },
                            {
                                path: '/goods/repeatOrder',
                                name: 'repeatOrder',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/repeatOrder.vue'),
                            },
                            {
                                path: '/goods/hotGoodViewCounter',
                                name: 'hotGoodViewCounter',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/hotGoodViewCounter.vue'),
                            },
                            {
                                path: '/goods/XXDViewCounter',
                                name: 'XXDViewCounter',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/XXDViewCounter.vue'),
                            },
                            {
                                path: '/goods/inTransitPreSale',
                                name: 'inTransitPreSale',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/zaituyushouGoodViewCouter.vue'),
                            },
                            {
                                path: '/goods/inTransitPreSaleNav',
                                name: 'inTransitPreSaleNav',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/zaituyushouGoodViewCouterNav.vue'),
                            },
                            {
                                path: '/goods/inProductionPreSaleNav',
                                name: 'inProductionPreSaleNav',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/zaichanyushouGoodViewCounterNav.vue'),
                            },
                            {
                                path: '/goods/inProductionPreSale',
                                name: 'inProductionPreSale',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/zaichanyushouGoodViewCounter.vue'),
                            },
                            {
                                path: '/goods/goodCartOrderBuy',
                                name: 'goodCartOrderBuy',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodCartOrderBuy.vue'),
                            },
                            {
                                path: '/goods/goodCartOrderBuyDB',
                                name: 'goodCartOrderBuyDB',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/goodCartOrderBuyDB.vue'),
                            },
                            {
                                path: '/goods/tuiGoodViewCounter',
                                name: 'tuiGoodViewCounter',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/tuiGoodViewCounter.vue'),
                            },
                            {
                                path: '/goods/activityDetail',
                                name: 'activityDetail',
                                component: () => import(/* webpackChunkName: "goods_index" */ '../views/main/goods/activityDetail.vue'),
                            },
                        ]
                    },
                ]
            },

            {
                path: '/member',
                name: 'Member',
                redirect: '/member/index',
                component: () => import(/* webpackChunkName: "member" */ '../views/main/member/Index.vue'),
                children: [
                    {
                        path: '/member/index',
                        name: 'MemberIndex',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "member_index" */ '../views/main/member/Member.vue'),
                    },
                    {
                        path: '/member/send_order',
                        name: 'SendOrder',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "send_order" */ '../views/main/member/SendOrder.vue'),
                    },
                    {
                        path: '/member/order',
                        name: 'Order',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "order" */ '../views/main/member/Order.vue'),
                    },
                    {
                        path: '/member/receipt',
                        name: 'Receipt',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "receipt" */ '../views/main/member/Receipt.vue'),
                    },
                    {
                        path: '/member/recharge',
                        name: 'Recharge',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "recharge" */ '../views/main/member/Recharge.vue'),
                    },
                    {
                        path: '/member/money_log',
                        name: 'MoneyLog',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "money_log" */ '../views/main/member/MoneyLog.vue'),
                    },
                    {
                        path: '/member/check_pieces',
                        name: 'CheckPieces',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "check_pieces" */ '../views/main/member/CheckPieces.vue'),
                    },
                    {
                        path: '/member/receive_order',
                        name: 'ReceiveOrder',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "receive_order" */ '../views/main/member/ReceiveOrder.vue'),
                    },
                    {
                        path: '/member/vip',
                        name: 'Vip',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "vip" */ '../views/main/member/Vip.vue'),
                    },
                    {
                        path: '/member/edit_password',
                        name: 'EditPassword',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "edit_password" */ '../views/main/member/EditPassword.vue'),
                    },
                    {
                        path: '/member/edit_member',
                        name: 'EditMember',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "edit_member" */ '../views/main/member/EditMember.vue'),
                    },
                    {
                        path: '/member/inviter',
                        name: 'Inviter',
                        meta: { requireAuth: true, },
                        component: () => import(/* webpackChunkName: "inviter" */ '../views/main/member/Inviter.vue'),
                    },
                    {
                        path: '/member/other_site',
                        name: 'OtherSite',
                        component: () => import(/* webpackChunkName: "other_site" */ '../views/main/member/OtherSite.vue'),
                    },
                    {
                        path: '/member/site_apply',
                        name: 'SiteApply',
                        component: () => import(/* webpackChunkName: "site_apply" */ '../views/main/member/SiteApply.vue'),
                    },
                    {
                        path: '/member/interface',
                        name: 'Interface',
                        component: () => import(/* webpackChunkName: "interface" */ '../views/main/member/Interface.vue'),
                    },
                    {
                        path: '/member/teamwork',
                        name: 'Teamwork',
                        component: () => import(/* webpackChunkName: "teamwork" */ '../views/main/member/Teamwork.vue'),
                    },
                    {
                        path: '/member/about',
                        name: 'About',
                        component: () => import(/* webpackChunkName: "about" */ '../views/main/member/About.vue'),
                    },
                    {
                        path: '/member/guide',
                        name: 'Guide',
                        component: () => import(/* webpackChunkName: "guide" */ '../views/main/member/Guide.vue'),
                    },
                    {
                        path: '/member/check_order',
                        name: 'CheckOrder',
                        component: () => import(/* webpackChunkName: "check_order" */ '../views/main/member/CheckOrder.vue'),
                    },
                    {
                        path: '/member/chose_goods',
                        name: 'ChoseGoods',
                        component: () => import(/* webpackChunkName: "chose_goods" */ '../views/main/member/ChoseGoods.vue'),
                    },
                    {
                        path: '/member/stop_area',
                        name: 'StopArea',
                        component: () => import(/* webpackChunkName: "chose_goods" */ '../views/main/member/StopArea.vue'),
                    },
                    {
                        path: '/member/withdraw',
                        name: 'Withdraw',
                        component: () => import(/* webpackChunkName: "chose_goods" */ '../views/main/member/Withdraw.vue'),
                    },
                    {
                        path: '/member/advice',
                        name: 'Advice',
                        component: () => import(/* webpackChunkName: "chose_goods" */ '../views/main/member/Advice.vue'),
                    },
                    {
                        path: '/member/advice_list',
                        name: 'AdviceList',
                        component: () => import(/* webpackChunkName: "chose_goods" */ '../views/main/member/AdviceList.vue'),
                    },
                ]
            },
            {
                path: '/news',
                name: 'News',
                component: () => import(/* webpackChunkName: "member" */ '../views/main/news/Index.vue'),
                
            },
            {
                path: '/NewDetail',
                name: 'NewDetail',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/news/Detail.vue'),
            },
            {
                path: '/Introduce',
                name: 'Introduce',
                component: () => import(/* webpackChunkName: "index" */ '../views/main/Introduce/index.vue'),
            },
            {
                path: '/about',
                name: 'Abouts',
                component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
            },
            {
                path: '/ad',
                name: 'Ad',
                component: () => import(/* webpackChunkName: "ad" */ '../views/Ad.vue'),
            }
        ]
    },
    {
        path: '/wxLogin',
        name: 'WxLogin',
        component: () => import(/* webpackChunkName: "register" */ '../views/WxLogin.vue'),
    },
    {
        path: '/m_index',
        name: 'M_index',
        component: () => import(/* webpackChunkName: "m_index" */ '../views/mobile/Index.vue'),
    },
    {
        path: '/m_login',
        name: 'M_login',
        component: () => import(/* webpackChunkName: "m_login" */ '../views/mobile/Login.vue'),
    },
    {
        path: '/m_register',
        name: 'M_register',
        component: () => import(/* webpackChunkName: "m_register" */ '../views/mobile/Register.vue'),
    },
    {
        path: '/m_forgetpassword',
        name: 'M_forgetpassword',
        component: () => import(/* webpackChunkName: "m_forgetpassword" */ '../views/mobile/ForgetPassword.vue'),
    },
    {
        path: '/m_goodsall',
        name: 'M_goodsall',
        component: () => import(/* webpackChunkName: "m_goodsall" */ '../views/mobile/GoodsAll.vue'),
    },
    {
        path: '/m_guide',
        name: 'M_guide',
        component: () => import(/* webpackChunkName: "m_guide" */ '../views/mobile/Guide.vue'),
    },
    {
        path: '/m_othersite',
        name: 'M_othersite',
        component: () => import(/* webpackChunkName: "m_othersite" */ '../views/mobile/OtherSite.vue'),
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.meta.requireAuth)) {
        var token = localStorage.getItem('dftToken');
        if (token) {
            next();
        }
        else {
            next({
                path: '/login',
                query: { redirect: to.path }
            })
        }
    }
    else {
        next();
    }
})

export default router

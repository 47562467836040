import Vue from 'vue'
import Vuex from 'vuex'

import SockJS from '@/utils/sockjs'
import '@/utils/stomp'
import { Notification } from 'element-ui';
import {
    groupMsgContent, getUsers
} from '@/api/wechat/index.js'
import {
    dchatCenterQueryUsers, chatCenterQueryChatToSbHistory, queryGroupchatMember, queryChatCustomer
} from "@/api/basicDossier";
// import {
//     imageHttps
// } from '@/config'
Vue.use(Vuex)

const now = new Date();

const state = () => ({

    routes: [],
    userContentMembers: {},
    dialogVisibleWchat: false,
    sessionsMemberList: [],
    sessions: {},//聊天记录
    sessionsMember: {},//聊天记录
    users: [],//用户列表
    Member: [],//群聊成员
    groupChat: [],//群聊
    currentUser: null,//当前登录用户
    currentSession: { userName: '群聊', nickname: '群聊' },//当前选中的用户，默认为群聊
    currentList: '私聊',//当前聊天窗口列表
    filterKey: '',
    stomp: null,
    drawer: false,
    userNickName: '',//用户昵称
    isDot: {},//两用户之间是否有未读信息
    errorImgUrl: "http://39.108.169.57/group1/M00/00/00/J2ypOV7wJkyAAv1fAAANuXp4Wt8303.jpg",//错误提示图片
    shotHistory: {}//拍一拍的记录历史
})
const getters = {
    userContentMembers: (state) => state.userContentMembers,
    routes: (state) => state.routes,
    sessionsMemberList: (state) => state.sessionsMemberList,
    sessions: (state) => state.sessions,
    users: (state) => state.users,
    dialogVisibleWchat: (state) => state.dialogVisibleWchat,
    Member: (state) => state.Member,
    groupChat: (state) => state.groupChat,
    sessionsMember: (state) => state.sessionsMember,
    currentUser: (state) => state.currentUser,
    currentSession: (state) => state.currentSession,
    currentList: (state) => state.currentList,
    filterKey: (state) => state.filterKey,
    stomp: (state) => state.stomp,
    drawer: (state) => state.drawer,
    isDot: (state) => state.isDot,
    errorImgUrl: (state) => state.errorImgUrl,
    shotHistory: (state) => state.shotHistory,
    userNickName: (state) => state.userNickName,

}
const mutations = {
    initRoutes(state, data) {
        state.routes = data;
    },
    setUserContentMembers(state, data) {
        state.userContentMembers = data;
    },
    userNickName(state, data) {
        state.userNickName = data;
    },
    setImmediaterawer(state, data) {
        state.drawer = data;
    },
    changeCurrentSession(state, currentSession) {
        //切换到当前用户就标识消息已读
        Vue.set(state.isDot, currentSession.userName, false);
        //更新当前选中的用户
        localStorage.setItem('chatName', + currentSession.userName)
        state.currentSession = currentSession;
    },
    //修改当前聊天窗口列表
    changeCurrentList(state, currentList) {
        state.currentList = currentList;
    },
    //保存群聊消息记录
    addGroupMessage(state, msg) {
        let message = state.sessionsMember[msg.groupChatName];
        if (!message) {
            //state.sessions[state.currentHr.username+"#"+msg.to]=[];
            Vue.set(state.sessionsMember, msg.groupChatName, []);
        }
        state.sessionsMember[msg.groupChatName].push({

            from: msg.from,
            content: msg.content,
            to: msg.to,
            type: msg.type,
            groupChatId: msg.groupChatId,
            createTime: msg.createTime,
            chatName: msg.groupChatName,
            headImageUrl: msg.headImageUrl,
        })
    },
    setMessages(state, data) {
        state.sessions = data;
    },
    setdialogVisibleWchat(state, data) {
        state.dialogVisibleWchat = data;
    },
    //保存单聊数据
    initMessage(state, msg) {
        let message = state.sessions[msg.to];
        console.log('message', message)
        if (!message) {
            //创建保存消息记录的数组
            Vue.set(state.sessions, msg.to, []);
        }

        state.sessions[msg.to] = msg.content

        console.log("state.sessions", state.sessions)
    },
    initMessageMembers(state, msg) {
        let message = state.sessionsMember[msg.to];
        console.log('message', message)
        if (!message) {
            //创建保存消息记录的数组
            Vue.set(state.sessionsMember, msg.to, []);
        }
        console.log('state.sessions[msg.to]', state.sessionsMember)

        state.sessionsMember[msg.to] = msg.content

        console.log("state.sessions", state.sessionsMember)
    },
    initMessageMembersList(state, msg) {


        state.sessionsMemberList = msg.content

        console.log("state.sessionsMemberList", state.sessionsMemberList)
    },
    addMessage(state, msg) {
        // chatCenterQueryChatToSbHistory({
        //     to: msg.to
        // }).then(res => {
        //     state.sessions[msg.to] = res.data
        //     console.log("state.sessions", state.sessions)
        // })
        console.log(msg)
        let message = state.sessions[msg.from];
        console.log("state.sessions", state.sessions)
        if (!message) {

            Vue.set(state.sessions, msg.to, []);
        }

        state.sessions[msg.from].push({
            content: msg.content,
            createTime: msg.createTime,
            from: msg.from,
            to: msg.to,
            type: msg.type,
             headImageUrl: msg.headImageUrl,
        })
        console.log("state.sessions", state.sessions)
        // console.log("state.sessions", state.sessions)
    },
    sendAddMessage(state, msg) {
        // chatCenterQueryChatToSbHistory({
        //     to: msg.to
        // }).then(res => {
        //     state.sessions[msg.to] = res.data
        //     console.log("state.sessions", state.sessions)
        // })
        console.log('msg', msg)
        let message = state.sessions[msg.to];
        console.log("state.sessions", state.sessions)
        if (!message) {

            Vue.set(state.sessions, msg.to, []);
        }

        state.sessions[msg.to].push({
            content: msg.content,
            createTime: msg.createTime,
            from: msg.from,
            to: msg.to,
            type: msg.type,
            headImageUrl: msg.headImageUrl,
        })
        console.log("state.sessions", state.sessions)
        // console.log("state.sessions", state.sessions)
    },
    sendAddMessageChat(state, msg) {
        // chatCenterQueryChatToSbHistory({
        //     to: msg.to
        // }).then(res => {
        //     state.sessions[msg.to] = res.data
        //     console.log("state.sessions", state.sessions)
        // })
        console.log('sendAddMessageChat', msg)
        let message = state.sessionsMember[msg.chatName];
        console.log("state.sessions", state.sessionsMember)
        if (!message) {

            Vue.set(state.sessionsMember, msg.chatName, []);
        }

        state.sessionsMember[msg.chatName].push({
            content: msg.content,
            createTime: msg.createTime,
            from: msg.from,
            to: msg.to,
            type: msg.type,
            headImageUrl: msg.headImageUrl,
        })
        console.log("state.sessions", state.sessionsMember)
        // console.log("state.sessions", state.sessions)
    },
    /**
     *  获取本地聊天记录，同步数据库的记录保存到localStorage中。
     *  不刷新情况下都是读取保存再localStorage中的记录
     * @param state
     * @constructor
     */
    INIT_DATA(state) {
        //同步数据库中的群聊数据
        console.log("同步数据库中的群聊数据")
        // groupMsgContent({}).then(resp => {
        //     console.log(resp)

        //     if (resp) {
        //         Vue.set(state.sessions, '群聊', resp);
        //     }
        // })
    },
    //保存系统所有用户
    INIT_USER(state, data) {
        state.users = data;
    },
    //请求并保存所有系统用户
    GET_USERS(state) {

        dchatCenterQueryUsers({}).then(resp => {
            if (resp.code == 200) {
                resp.data.forEach(element => {
                    element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
                });
                if (resp) {
                    state.users = resp.data;
                    state.dialogVisibleWchat = true
                }
                queryChatCustomer({}).then(resp1 => {
                    if (resp1.code == 200) {
                        resp1.data.forEach(element => {
                            element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
                        });
                        if (resp1) {
                            state.groupChat = resp1.data;
                            console.log("resp.data", resp1.data)
                        }
                        queryGroupchatMember({}).then(resp2 => {
                            if (resp2.code == 200) {
                                resp2.data.forEach(element => {
                                    element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
                                });
                                if (resp2) {
                                    state.Member = resp2.data;
                                }
                            }

                        })
                    }

                })
            }


        })



    }
}
const actions = {
    /**
     * 作用：初始化数据
     * action函数接受一个与store实例具有相同方法和属性的context对象
     * @param context
     */
    initData(context) {
        console.log("初始化数据")
        //初始化聊天记录
        context.commit('INIT_DATA')
        //获取用户列表
        console.log("获取用户列表")
        context.commit('GET_USERS')
    },
    /**
     * 实现连接服务端连接与消息订阅
     * @param context 与store实例具有相同方法和属性的context对象
     */
    connect(context) {
        //连接Stomp站点
        let headers = { login: localStorage.getItem('loginAccount'), passcode: '12345', 'nickName': localStorage.getItem('userContent').customername }
        context.state.stomp = Stomp.over(new SockJS('ws/ep'));
        // context.state.stomp = Stomp.over(new SockJS('http://localhost:8085/chat'));
        console.log('23123123', context.state.stomp.connect)

        context.state.stomp.connect(headers, success => {
            console.log("连接成功", success);
            /**
             * 订阅用户登录通知
             */

            /**
             * 订阅系统广播通知消息
             */
            // context.state.stomp.subscribe("/topic/notification", msg => {
            //     console.log("收到系统消息" + msg.body);
            //     //判断是否是系统广播通知
            //     Notification.info({
            //         title: '系统消息',
            //         message: msg.body.substr(5),
            //         position: "top-right"
            //     });
            //     //更新用户列表（的登录状态）
            //     context.commit('GET_USERS');
            // });
            /**
             * 订阅群聊消息
             */
            context.state.stomp.subscribe("/user/" + localStorage.getItem('loginAccount') + "/queue/groupChat/", msg => {
                console.log("收到群聊消息", JSON.parse(msg.body));
                //接收到的消息数据
                let receiveMsg = JSON.parse(msg.body);
                //当前点击的聊天界面不是群聊,默认为消息未读
                if (context.state.currentList != '客户') {
                    Notification.info({
                        title: '群聊【' + receiveMsg.groupChatName + '】发来一条消息',
                        message: receiveMsg.content.length < 8 ? receiveMsg.content : receiveMsg.content.substring(0, 8) + "...",
                        position: "top-right"
                    });
                }
                //提交消息记录
                context.commit('addGroupMessage', receiveMsg);
            });
            /**
             * 订阅机器人回复消息
             */
            // context.state.stomp.subscribe("/user/queue/robot", msg => {
            //     //接收到的消息
            //     let receiveMsg = JSON.parse(msg.body);
            //     //标记为机器人回复
            //     receiveMsg.notSelf = true;
            //     receiveMsg.to = '机器人';
            //     receiveMsg.messageTypeId = 1;
            //     //添加到消息记录保存
            //     context.commit('addMessage', receiveMsg);
            // })
            /**
             * 订阅私人消息
             */
            context.state.stomp.subscribe('/user/' + localStorage.getItem('loginAccount') + '/queue/chat', msg => {
                //接收到的消息数据
                let receiveMsg = JSON.parse(msg.body);
                //没有选中用户或选中用户不是发来消息的那一方
                if (!context.state.currentSession || receiveMsg.from != context.state.currentSession.username) {
                    Notification.info({
                        title: '【' + receiveMsg.from + '】发来一条消息',
                        message: receiveMsg.content.length < 8 ? receiveMsg.content : receiveMsg.content.substring(0, 8) + "...",
                        position: "top-right"
                    });
                    //默认为消息未读
                    // Vue.set(context.state.isDot, context.state.currentUser.username + receiveMsg.from, true);
                }
                //标识这个消息不是自己发的
                // receiveMsg.notSelf = true;
                //获取发送方
                // receiveMsg.to = receiveMsg.from;
                //提交消息记录
                console.log(msg.body)
                context.commit('addMessage', receiveMsg);
            })
        }, error => {
            Notification.info({
                title: '系统消息',
                message: "无法与服务端建立连接，请尝试重新登陆系统~",
                position: "top-right"
            });
        })
    },
    //与Websocket服务端断开连接
    disconnect(context) {
        if (context.state.stomp != null) {
            context.state.stomp.disconnect();
            console.log("关闭连接~");
        }
    },
}
export default {
    state,
    getters,
    mutations,
    actions,
}

/**
 * 监听state.sessions，有变化就重新保存到local Storage中chat-session中
//  */
// store.watch(function (state) {
//     return state.sessions
// }, function (val) {
//     console.log('CHANGE: ', val);
//     localStorage.setItem('chat-session', JSON.stringify(val));
// }, {
//     deep: true/*这个貌似是开启watch监测的判断,官方说明也比较模糊*/
// })


// export default store;

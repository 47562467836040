import request from "@/utils/request";

// --------------------------------------------------------------------------------------------------------区域
// 区域查询
export function dcBasearchiveArea(params) {
  return request({
    url: "/dcBasearchiveArea",
    method: "get",
    params,
  });
}

// 区域新增
export function dcBasearchiveAreaAdd(data) {
  return request({
    url: "/dcBasearchiveArea",
    method: "post",
    data,
  });
}

// 区域修改
export function dcBasearchiveAreaUpdata(data) {
  return request({
    url: "/dcBasearchiveArea",
    method: "put",
    data,
  });
}

// 区域修改
export function dcBasearchiveAreaDelete(id) {
  return request({
    url: "/dcBasearchiveArea/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------人员
// u8人员
export function selectAllhrHiPerson(params) {
  return request({
    url: "/dcBasearchivePerson",
    method: "get",
    params,
  });
}

export function selectAllhrHiPersonUpdata(data) {
  return request({
    url: "/dcBasearchivePerson",
    method: "put",
    data,
  });
}

// --------------------------------------------------------------------------------------------------------品牌
// 品牌查询
export function dcBasearchiveBrand(params) {
  return request({
    url: "/dcBasearchiveBrand",
    method: "get",
    params,
  });
}

// 品牌新增
export function dcBasearchiveBrandAdd(data) {
  return request({
    url: "/dcBasearchiveBrand",
    method: "post",
    data,
  });
}

// 品牌修改
export function dcBasearchiveBrandUpdata(data) {
  return request({
    url: "/dcBasearchiveBrand",
    method: "put",
    data,
  });
}

// 品牌删除
export function dcBasearchiveBrandDelete(id) {
  return request({
    url: "/dcBasearchiveBrand/" + id,
    method: "delete",
  });
}

// 查询可用品牌
export function enableListPage(params) {
  return request({
    url: "/dcBasearchiveBrand/enableListPage",
    method: "get",
    params,
  });
}

// --------------------------------------------------------------------------------------------------------颜色
// 颜色查询
export function dcBasearchiveColor(params) {
  return request({
    url: "/dcBasearchiveColor",
    method: "get",
    params,
  });
}

// 颜色新增
export function dcBasearchiveColorAdd(data) {
  return request({
    url: "/dcBasearchiveColor",
    method: "post",
    data,
  });
}

// 颜色修改
export function dcBasearchiveColorUpdata(data) {
  return request({
    url: "/dcBasearchiveColor",
    method: "put",
    data,
  });
}

// 颜色删除
export function dcBasearchiveColorDelete(id) {
  return request({
    url: "/dcBasearchiveColor/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------颜色
// 类别查询
export function dcBasearchiveCategory(params) {
  return request({
    url: "/dcBasearchiveCategory",
    method: "get",
    params,
  });
}

// 类别新增
export function dcBasearchiveCategoryAdd(data) {
  return request({
    url: "/dcBasearchiveCategory",
    method: "post",
    data,
  });
}

// 类别修改
export function dcBasearchiveCategoryUpdata(data) {
  return request({
    url: "/dcBasearchiveCategory",
    method: "put",
    data,
  });
}

// 类别删除
export function dcBasearchiveCategoryDelete(id) {
  return request({
    url: "/dcBasearchiveCategory/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------国家
// 国家查询
export function dcBasearchiveRegion(params) {
  return request({
    url: "/dcBasearchiveRegion",
    method: "get",
    params,
  });
}

// 国家新增
export function dcBasearchiveRegionAdd(data) {
  return request({
    url: "/dcBasearchiveRegion",
    method: "post",
    data,
  });
}

// 国家修改
export function dcBasearchiveRegionUpdata(data) {
  return request({
    url: "/dcBasearchiveRegion",
    method: "put",
    data,
  });
}

// 国家删除
export function dcBasearchiveRegionDelete(id) {
  return request({
    url: "/dcBasearchiveRegion/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------客户等级
// 客户等级查询
export function dcBasearchiveCustomerlevel(params) {
  return request({
    url: "/dcBasearchiveCustomerlevel",
    method: "get",
    params,
  });
}

// 客户等级新增
export function dcBasearchiveCustomerlevelAdd(data) {
  return request({
    url: "/dcBasearchiveCustomerlevel",
    method: "post",
    data,
  });
}

// 客户等级修改
export function dcBasearchiveCustomerlevelUpdata(data) {
  return request({
    url: "/dcBasearchiveCustomerlevel",
    method: "put",
    data,
  });
}

// 客户等级删除
export function dcBasearchiveCustomerlevelDelete(id) {
  return request({
    url: "/dcBasearchiveCustomerlevel/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------商品标签
// 商品标签查询
export function dcBasearchiveLabel(params) {
  return request({
    url: "/dcBasearchiveLabel",
    method: "get",
    params,
  });
}

// 商品标签新增
export function dcBasearchiveLabelAdd(data) {
  return request({
    url: "/dcBasearchiveLabel",
    method: "post",
    data,
  });
}

// 商品标签修改
export function dcBasearchiveLabelUpdata(data) {
  return request({
    url: "/dcBasearchiveLabel",
    method: "put",
    data,
  });
}

// 商品标签删除
export function dcBasearchiveLabelDelete(id) {
  return request({
    url: "/dcBasearchiveLabel/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------商城首页视频图片
// 图片/视频上传
export function addCommodityFile(data) {
  return request({
    url: "/dcBasearchiveCommodityFile/addCommodityFile",
    method: "post",
    data,
  });
}
export function chatCenterAddFile(data) {
  return request({
    url: "/chatCenter/addFile",
    method: "post",
    data,
  });
}
// 图片/视频查询
export function getCommodityFile(params) {
  return request({
    url: "/dcBasearchiveCommodityFile/getCommodityFile",
    method: "get",
    params,
  });
}

// 图片删除
export function CommodityFileDelete(id) {
  return request({
    url: "/dcBasearchiveCommodityFile/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------营销组织
export function dcMarketingcenterOrganization(params) {
  return request({
    url: "/dcMarketingcenterOrganization",
    method: "get",
    params,
  });
}

export function dcMarketingcenterOrganizationUpdata(data) {
  return request({
    url: "/dcMarketingcenterOrganization",
    method: "put",
    data,
  });
}

// --------------------------------------------------------------------------------------------------------商品
// 商品列表
export function dcBasearchiveCommodity(params) {
  return request({
    url: "/dcBasearchiveCommodity",
    method: "get",
    params,
  });
}

// 商品修改
export function dcBasearchiveCommodityUpdata(data) {
  return request({
    url: "/dcBasearchiveCommodity",
    method: "put",
    data,
  });
}

// 广州批量商品修改
export function batchUpdate(data) {
  return request({
    url: "/dcBasearchiveCommodity/batchUpdate",
    method: "put",
    data,
  });
}

// 迪拜批量商品修改
export function batchUpdate1(data) {
  return request({
    url: "/dcBasearchiveCommodity/batchUpdate1",
    method: "put",
    data,
  });
}

// 商品配货清单列表
export function dcBasearchiveCommodityPackingratio(params) {
  return request({
    url: "/dcBasearchiveCommodityPackingratio",
    method: "get",
    params,
  });
}

// 商品配货清单上传图片
export function addCommodityColorImage(data) {
  return request({
    url: "dcBasearchiveCommodityPackingratio/addCommodityColorImage",
    method: "post",
    data,
  });
}

// 商品配货清单删除图片
export function addCommodityColorImageDelete(data) {
  return request({
    url: "dcBasearchiveCommodityPackingratio/deleteCommodityColorImage",
    method: "post",
    data,
  });
}

// u8类别查询
export function inventoryclass(params) {
  return request({
    url: "inventoryclass",
    method: "get",
    params,
  });
}

// 删除图片
export function deleteCommodityFile(data) {
  return request({
    url: "dcBasearchiveCommodityFile/deleteCommodityFile",
    method: "delete",
    data,
  });
}

// 图片顺序调整
export function modCommodityFile(data) {
  return request({
    url: "dcBasearchiveCommodityFile/modCommodityFile",
    method: "post",
    data,
  });
}

//  商品导出上架
export function dcBasearchiveCommodityExport(params) {
  return request({
    url: "dcBasearchiveCommodity/export",
    method: "get",
    params,
    responseType: "blob",
  });
}
export function dcBasearchiveCommodityExportDB(params) {
  return request({
    url: "dcBasearchiveCommodity/export1",
    method: "get",
    params,
    responseType: "blob",
  });
}
// --------------------------------------------------------------------------------------------------------通知公告
// 查询
export function dcMarketingcenterNotice(params) {
  return request({
    url: "dcMarketingcenterNotice",
    method: "get",
    params,
  });
}

// 新增
export function dcMarketingcenterNoticeAdd(data) {
  return request({
    url: "dcMarketingcenterNotice",
    method: "post",
    data,
  });
}

// 修改
export function dcMarketingcenterNoticeUpdata(data) {
  return request({
    url: "dcMarketingcenterNotice",
    method: "put",
    data,
  });
}

// 删除
export function dcMarketingcenterNoticeDelete(id) {
  return request({
    url: "dcMarketingcenterNotice/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------新客审核
// 新客查询(新注册)
export function dcCustomercenterNewcustomerreview(params) {
  return request({
    url: "dcCustomercenterNewcustomerreview",
    method: "get",
    params,
  });
}

// 新客审核
export function dcCustomercenterNewcustomerreviewReviewe(data) {
  return request({
    url: "dcCustomercenterNewcustomerreview/reviewe",
    method: "post",
    data,
  });
}

// 新客审核生成用户
export function generationUser(data) {
  return request({
    url: "dcCustomercenterNewcustomerreview/generationUser",
    method: "put",
    data,
  });
}

// 新客审核-客户分类
export function categoryCustomer(params) {
  return request({
    url: "customerclass",
    method: "get",
    params,
  });
}

// 新客审核-写入U8
export function sendU8(data) {
  return request({
    url: "dcCustomercenterNewcustomerreview/saveInU8",
    method: "put",
    data,
  });
}

// 校验回访
export function dcCustomercenterNewcustomerreviewVerify(data) {
  return request({
    url: "dcCustomercenterNewcustomerreview/verify",
    method: "post",
    data,
  });
}

// 客户查询(新客已审核)
export function dcCustomercenterCustomer(params) {
  return request({
    url: "dcCustomercenterCustomer",
    method: "get",
    params,
  });
}

// 客户查询(新客已审核)  (新)
export function customerAll(params) {
  return request({
    url: "dcCustomercenterCustomer/queryAll",
    method: "get",
    params,
  });
}

// 客户上传收货地址图片
export function addCustomerAddressImage(data) {
  return request({
    url: "dcCustomercenterCustomeraddress/addCustomerAddressImage",
    method: "post",
    data,
  });
}

// 删除图片
export function deleteCustomerAddressImage(data) {
  return request({
    url: "dcCustomercenterCustomeraddress/deleteCustomerAddressImage",
    method: "post",
    data,
  });
}

// 客户修改
export function dcCustomercenterCustomerUpdata(data) {
  return request({
    url: "dcCustomercenterCustomer",
    method: "put",
    data,
  });
}

// 查询客户地址
export function dcCustomercenterCustomeraddress(params) {
  return request({
    url: "dcCustomercenterCustomeraddress",
    method: "get",
    params,
  });
}

// 新增客户地址
export function dcCustomercenterCustomeraddressAdd(data) {
  return request({
    url: "dcCustomercenterCustomeraddress",
    method: "post",
    data,
  });
}

// 修改客户地址
export function dcCustomercenterCustomeraddressUpdata(data) {
  return request({
    url: "dcCustomercenterCustomeraddress",
    method: "put",
    data,
  });
}

// 删除客户地址
export function dcCustomercenterCustomeraddressDelete(id) {
  return request({
    url: "dcCustomercenterCustomeraddress/" + id,
    method: "delete",
  });
}

// --------------------------------------------------------------------------------------------------------客户年度任务
// 查询
export function dcCustomercenterCustomerannualtask(params) {
  return request({
    url: "dcCustomercenterCustomerannualtask",
    method: "get",
    params,
  });
}
// 修改
export function dcCustomercenterCustomerannualtaskUpdata(data) {
  return request({
    url: "dcCustomercenterCustomerannualtask",
    method: "put",
    data,
  });
}
// 删除
export function dcCustomercenterCustomerannualtaskDelete(id) {
  return request({
    url: "dcCustomercenterCustomerannualtask/" + id,
    method: "delete",
  });
}
// 新增
export function dcCustomercenterCustomerannualtaskAdd(data) {
  return request({
    url: "dcCustomercenterCustomerannualtask",
    method: "post",
    data,
  });
}

// --------------------------------------------------------------------------------------------------------客户信用
// 查询
export function dcCustomercenterCustomercredit(params) {
  return request({
    url: "dcCustomercenterCustomercredit",
    method: "get",
    params,
  });
}
// 修改
export function dcCustomercenterCustomercreditEdit(data) {
  return request({
    url: "dcCustomercenterCustomercredit/edit",
    method: "post",
    data,
  });
}

// 审核
export function dcCustomercenterCustomercreditReviewe(data) {
  return request({
    url: "dcCustomercenterCustomercredit/reviewe",
    method: "post",
    data,
  });
}

// 删除
export function dcCustomercenterCustomercreditDelete(id) {
  return request({
    url: "dcCustomercenterCustomercredit/" + id,
    method: "delete",
  });
}

// 新增
export function dcCustomercenterCustomercreditAdd(data) {
  return request({
    url: "dcCustomercenterCustomercredit",
    method: "post",
    data,
  });
}

// --------------------------------------------------------------------------------------------------------商品可见
// 查询商品可见条件
export function dcCustomercenterCommodityvisible(params) {
  return request({
    url: "dcCustomercenterCommodityvisible",
    method: "get",
    params,
  });
}

// 商品可见修改
export function dcCustomercenterCommodityvisibleUpdata(data) {
  return request({
    url: "dcCustomercenterCommodityvisible",
    method: "put",
    data,
  });
}

// 商品可见新增
export function dcCustomercenterCommodityvisibleAdd(data) {
  return request({
    url: "dcCustomercenterCommodityvisible",
    method: "post",
    data,
  });
}

// 商品可见删除
export function dcCustomercenterCommodityvisibleDelete(id) {
  return request({
    url: "dcCustomercenterCommodityvisible/" + id,
    method: "delete",
  });
}

// 商品可见查询
export function queryCustomerAssignCommodity(params) {
  return request({
    url: "dcBasearchiveCommodity/queryCustomerAssignCommodity",
    method: "get",
    params,
  });
}

// --------------------------------------------------------------------------------------------------------营销日历
// 营销日历查询
export function dcMarketingcenterMarketingcalendar(params) {
  return request({
    url: "dcMarketingcenterMarketingcalendar",
    method: "get",
    params,
  });
}

// 营销日历新增
export function dcMarketingcenterMarketingcalendarAdd(data) {
  return request({
    url: "dcMarketingcenterMarketingcalendar",
    method: "post",
    data,
  });
}

// 营销日历修改
export function dcMarketingcenterMarketingcalendarUpdata(data) {
  return request({
    url: "dcMarketingcenterMarketingcalendar",
    method: "put",
    data,
  });
}

// 营销日历删除
export function dcMarketingcenterMarketingcalendarDel(id) {
  return request({
    url: "dcMarketingcenterMarketingcalendar/" + id,
    method: "delete",
  });
}

// -------------------------------------------------------------------------------------------------------- 热销/首推商品

// 热销/首推复制
export function dcMarketingcenterMarketingcommodityCopy(data) {
  return request({
    url: "dcMarketingcenterMarketingcommodity/copy",
    method: "post",
    data,
  });
}

// 热销/首推查询
export function dcMarketingcenterMarketingcommodity(params) {
  return request({
    url: "dcMarketingcenterMarketingcommodity",
    method: "get",
    params,
  });
}

// 热销/首推新增
export function dcMarketingcenterMarketingcommodityAdd(data) {
  return request({
    url: "dcMarketingcenterMarketingcommodity",
    method: "post",
    data,
  });
}

// 热销/首推修改
export function dcMarketingcenterMarketingcommodityUpdata(data) {
  return request({
    url: "dcMarketingcenterMarketingcommodity",
    method: "put",
    data,
  });
}

// 热销/首推删除
export function dcMarketingcenterMarketingcommodityDel(id) {
  return request({
    url: "dcMarketingcenterMarketingcommodity/" + id,
    method: "delete",
  });
}

// -------------------------------------------------------------------------------------------------------- 新品预售
// 新品预售复制
export function dcMarketingcenterNewcommodityCopy(data) {
  return request({
    url: "dcMarketingcenterNewcommodity/copy",
    method: "post",
    data,
  });
}

// 新品预售查询
export function dcMarketingcenterNewcommodity(params) {
  return request({
    url: "dcMarketingcenterNewcommodity",
    method: "get",
    params,
  });
}

// 新品预售新增
export function dcMarketingcenterNewcommodityAdd(data) {
  return request({
    url: "dcMarketingcenterNewcommodity",
    method: "post",
    data,
  });
}

// 新品预售新增图片
export function dcMarketingcenterNewcommodityAddImg(data) {
  return request({
    url: "dcMarketingcenterNewcommodity/addImage",
    method: "post",
    data,
  });
}

// 新品预售修改
export function dcMarketingcenterNewcommodityUpdata(data) {
  return request({
    url: "dcMarketingcenterNewcommodity",
    method: "put",
    data,
  });
}

// 新品预售删除
export function dcMarketingcenterNewcommodityDel(id) {
  return request({
    url: "dcMarketingcenterNewcommodity/" + id,
    method: "delete",
  });
}

// -------------------------------------------------------------------------------------------------------- 允销仓库
// 允销仓库查询
export function dcBasearchiveSellingwarehouse(params) {
  return request({
    url: "dcBasearchiveSellingwarehouse",
    method: "get",
    params,
  });
}

// 允销仓库添加
export function dcBasearchiveSellingwarehouseAdd(data) {
  return request({
    url: "dcBasearchiveSellingwarehouse",
    method: "post",
    data,
  });
}

// 允销仓库修改
export function dcBasearchiveSellingwarehouseUpdata(data) {
  return request({
    url: "dcBasearchiveSellingwarehouse",
    method: "put",
    data,
  });
}
export function dcBasearchiveSellingwarehouseUpdataAll(data) {
  return request({
    url: "dcBasearchiveSellingwarehouse/editBatch",
    method: "put",
    data,
  });
}
// 允销仓库删除
export function dcBasearchiveSellingwarehouseDel(id) {
  return request({
    url: "dcBasearchiveSellingwarehouse/" + id,
    method: "delete",
  });
}

// 查询所有仓库列表
export function queryAllWarehousePage(params) {
  return request({
    url: "dcBasearchiveSellingwarehouse/queryAllWarehousePage",
    method: "get",
    params,
  });
}

// -------------------------------------------------------------------------------------------------------- 允销仓库
// 库存查询
export function dcMarketingcenterSellcurrentstock(params) {
  return request({
    url: "dcMarketingcenterSellcurrentstock",
    method: "get",
    params,
  });
}

// -------------------------------------------------------------------------------------------------------- 在产预售
// 在产预售复制
export function productingCommodityCopy(data) {
  return request({
    url: "dcMarketingcenterProductingcommodity/copy",
    method: "post",
    data,
  });
}

// 在产预售订单查询
export function productionOrderQuery(params) {
  return request({
    url: "dcMarketingcenterProductingcommodity/productionOrderQuery",
    method: "get",
    params,
  });
}

// 新增在产预售查询
export function productingCommodity(params) {
  return request({
    url: "dcMarketingcenterProductingcommodity",
    method: "get",
    params,
  });
}

// 在产预售添加
export function productingCommodityAdd(data) {
  return request({
    url: "dcMarketingcenterProductingcommodity",
    method: "post",
    data,
  });
}

// 在产预售修改
export function productingCommodityUpdata(data) {
  return request({
    url: "dcMarketingcenterProductingcommodity",
    method: "put",
    data,
  });
}

// 在产预售删除
export function productingCommodityDel(id) {
  return request({
    url: "dcMarketingcenterProductingcommodity/" + id,
    method: "delete",
  });
}

// -------------------------------------------------------------------------------------------------------- 在途预售
// 在途预售复制
export function dcMarketingcenterTransportcommodityCopy(data) {
  return request({
    url: "dcMarketingcenterTransportcommodity/copy",
    method: "post",
    data,
  });
}

// 在途预售订单查询
export function productionOrderQueryS(params) {
  return request({
    url: "dcMarketingcenterTransportcommodity/transportOrderQuery",
    method: "get",
    params,
  });
}

// 新增在途预售查询
export function dcMarketingcenterTransportcommodity(params) {
  return request({
    url: "dcMarketingcenterTransportcommodity",
    method: "get",
    params,
  });
}

// 在途预售添加
export function dcMarketingcenterTransportcommodityAdd(data) {
  return request({
    url: "dcMarketingcenterTransportcommodity",
    method: "post",
    data,
  });
}

// 在途预售修改
export function dcMarketingcenterTransportcommodityUpdata(data) {
  return request({
    url: "dcMarketingcenterTransportcommodity",
    method: "put",
    data,
  });
}

// 在途预售删除
export function dcMarketingcenterTransportcommodityDel(id) {
  return request({
    url: "dcMarketingcenterTransportcommodity/" + id,
    method: "delete",
  });
}
export function addCustomerOrderPaymentImage(data) {
  return request({
    url: "/dcOrdercenterOrderPayment/addCustomerOrderPaymentImage",
    method: "post",
    data,
  });
}
// -------------------------------------------------------------------------------------------------------- 订单

// 商品阶梯价
export function queryCommodity(params) {
  return request({
    url: "salesManCenter/queryCommodity",
    method: "get",
    params,
  });
}

// 代客下单
export function orderForCustomer(data) {
  return request({
    url: "salesManCenter/orderForCustomer",
    method: "post",
    data,
  });
}

// 代客下单查询
export function queryOrderForCustomer(params) {
  return request({
    url: "salesManCenter/queryOrderForCustomer",
    method: "get",
    params,
  });
}

// 订单发货详情
export function queryDeliverOrder(params) {
  return request({
    url: "dcOrdercenterOrder/queryDeliverOrder",
    method: "get",
    params,
  });
}

// 客户订单查询
export function dcOrdercenterOrder(params) {
  return request({
    url: "dcOrdercenterOrder/queryOrderCollection",
    method: "get",
    params,
  });
}
export function dcOrdercenterOrderbak(params) {
  return request({
    url: "/dcOrdercenterOrder/bak",
    method: "get",
    params,
  });
}
export function doDownloadskdgz(params) {
  return request({
    url: "dcOrdercenterReceipt/export",
    method: "get",
    responseType: "blob",
    params,
  });
}
export function doDownloadskddb(params) {
  return request({
    url: "/dcOrdercenterReceipt/export1",
    method: "get",
    responseType: "blob",
    params,
  });
}

export function dcOrdercenterOrderYWY(params) {
  return request({
    url: "dcOrdercenterOrder/queryThemselfOrder",
    method: "get",
    params,
  });
} export function dcOrdercenterOrderYWY1(params) {
  return request({
    url: "salesManCenter/queryThemSelfOrderCollection",
    method: "get",
    params,
  });
}

// 客户订单查询
export function dcOrdercenterOrderKH(params) {
  return request({
    url: "salesManCenter/queryCustomerOrder",
    method: "get",
    params,
  });
}
// 订单发运方式查询
export function shippingchoice(params) {
  return request({
    url: "shippingchoice",
    method: "get",
    params
  });
}

// 订单库存选择
export function queryOrderCommodityStock(params) {
  return request({
    url: "dcOrdercenterOrder/queryOrderCommodityStock",
    method: "get",
    params,
  });
}

// 拒绝客户订单
export function dcOrdercenterOrderReject(data) {
  return request({
    url: "dcOrdercenterOrder/reject",
    method: "put",
    data,
  });
}

// 同意客户订单
export function dcOrdercenterOrderAgree(data) {
  return request({
    url: "dcOrdercenterOrder/agree",
    method: "put",
    data,
  });
}

// 关闭客户订单
export function dcOrdercenterOrdershutDown(data) {
  return request({
    url: "dcOrdercenterOrder/close",
    method: "put",
    data,
  });
}

export function dcOrdercenterOrderUpdata(data) {
  return request({
    url: "dcOrdercenterOrder",
    method: "put",
    data,
  });
}

export function dcOrdercenterOrderDelete(id) {
  return request({
    url: "dcOrdercenterOrder/" + id,
    method: "delete",
  });
}

// 修改购买量更新金额单价
export function editOrderCommodityNumber(data) {
  return request({
    url: "dcOrdercenterOrder/editOrderCommodityNumber",
    method: "put",
    data,
  });
}

// 查看订单收款记录
export function dcOrdercenterReceipt(params) {
  return request({
    url: "dcOrdercenterReceipt",
    method: "get",
    params,
  });
}

// 新增订单收款记录
export function dcOrdercenterReceiptAdd(data) {
  return request({
    url: "dcOrdercenterReceipt",
    method: "post",
    data,
  });
}
export function reSendInvoice(data) {
  return request({
    url: "dcOrdercenterOrder/reSendInvoice",
    method: "post",
    data,
  });
}
// 合并收款查订单
export function queryReceiptOrder(params) {
  return request({
    url: "dcOrdercenterOrder/queryReceiptOrder",
    method: "get",
    params,
  });
}
// 添加收款单
export function addReceipt(data) {
  return request({
    url: "dcOrdercenterReceipt/addReceipt",
    method: "post",
    data,
  });
}
// -------------------------------------------------------------------------------------------------------- 生成销售发票
// 生成销售发票
export function financialReview(data) {
  return request({
    url: "dcOrdercenterOrder/financialReview",
    method: "put",
    data,
  });
}
export function discountChangeFinancialReview(data) {
  return request({
    url: "dcOrdercenterOrder/orderDiscount",
    method: "put",
    data,
  });
}


export function dcRevokeInvoice(data) {
  return request({
    url: "dcOrdercenterOrder/revokeInvoice",
    method: "post",
    data,
  });
}

export function doDownload(params) {
  return request({
    url: "/dcBasearchiveCommodity/export",
    method: "get",
    responseType: "blob",
    params,
  });
}

export function doDownload1(params) {
  return request({
    url: "/dcBasearchiveCommodity/export1",
    method: "get",
    responseType: "blob",
    params,
  });
}
export function getDcpPrintOrder(params) {
  return request({
    url: "dcOrdercenterOrder/printOrder",
    method: "get",
    params,
  });
}

export function exportPackingOrder(params) {
  return request({
    url: "/dcOrdercenterOrder/exportPackingOrder",
    method: "get",
    responseType: "blob",
    params,
  });
}
export function exportPackingOrders(params) {
  return request({
    url: "/dcOrdercenterOrder/exportOrderAndDetail",
    method: "get",
    responseType: "blob",
    params,
  });
}
export function dcOrdercenterOrderReAgree(data) {
  return request({
    url: "dcOrdercenterOrder/reAgree",
    method: "post",
    data,
  });
}
export function dcCustomercenterCustomerOther(params) {
  return request({
    url: "dcCustomercenterCustomer/canOrderBySaleManCustomerPage",
    method: "get",
    params,
  });
}
export function getDcCustomercenterCustomernoreplace(params) {
  return request({
    url: "dcCustomercenterCustomernoreplace",
    method: "get",
    params,
  });
}
export function addDcCustomercenterCustomernoreplace(data) {
  return request({
    url: "dcCustomercenterCustomernoreplace",
    method: "post",
    data,
  });
}
export function deleteDcCustomercenterCustomernoreplace(data) {
  return request({
    url: "dcCustomercenterCustomernoreplace",
    method: "delete",
    data,
  });
}
export function getQueryMallSettleType(params) {
  return request({
    url: "dcOrdercenterOrder/queryMallSettleType",
    method: "get",
    params,
  });
}
export function queryOrderCommodityStockChange(params) {
  return request({
    url: "dcOrdercenterOrder/queryByOrderId1",
    method: "get",
    params,
  });
}
export function editDcOrdercenterOrder(data) {
  return request({
    url: "dcOrdercenterOrder",
    method: "put",
    data,
  });
}
export function getDcCustomercenterCustomeraddress(params) {
  return request({
    url: "dcCustomercenterCustomeraddress",
    method: "get",
    params,
  });
}
export function dcOrdercenterReceiptAgree(data) {
  return request({
    url: "dcOrdercenterReceipt/agree",
    method: "post",
    data,
  });
}
export function dcOrdercenterReceiptReAgree(data) {
  return request({
    url: "dcOrdercenterReceipt/reAgree",
    method: "post",
    data,
  });
}
// export function dcOrdercenterReceipt(params) {
//   return request({
//     url: 'dcOrdercenterReceipt',
//     method: 'get',
//     params
//   })
// }
export function getDcListSettleStyle(params) {
  return request({
    url: "dcOrdercenterReceipt/listSettleStyle",
    method: "get",
    params,
  });
}
export function editDcOrdercenterReceipt(data) {
  return request({
    url: "dcOrdercenterReceipt",
    method: "put",
    data,
  });
}

export function getdcOrdercenterReceipt(params) {
  return request({
    url: "dcOrdercenterReceipt/listCurrency",
    method: "get",
    params,
  });
}
// --------------------------------------------------------------------------------------------样板仓库
// 允销仓库查询
export function dcBasearchiveSellingwarehouseSample(params) {
  return request({
    url: "dcBasearchiveSamplewarehouse",
    method: "get",
    params,
  });
}

// 允销仓库添加
export function dcBasearchiveSellingwarehouseAddSample(data) {
  return request({
    url: "dcBasearchiveSamplewarehouse",
    method: "post",
    data,
  });
}

// // 允销仓库修改
// export function dcBasearchiveSellingwarehouseUpdata(data) {
//   return request({
//     url: 'dcBasearchiveSamplewarehouse',
//     method: 'put',
//     data
//   })
// }
// export function dcBasearchiveSellingwarehouseUpdataAll(data) {
//   return request({
//     url: 'dcBasearchiveSellingwarehouse/editBatch',
//     method: 'put',
//     data
//   })
// }
// 允销仓库删除
export function dcBasearchiveSellingwarehouseDelSample(id) {
  return request({
    url: "dcBasearchiveSamplewarehouse/" + id,
    method: "delete",
  });
}

// 查询所有仓库列表
export function queryAllWarehousePageSample(params) {
  return request({
    url: "dcBasearchiveSamplewarehouse/queryAddWarehousePage",
    method: "get",
    params,
  });
}

export function dcBasearchiveCommodityPackingratioQuerySellStock(params) {
  return request({
    url: "/dcBasearchiveCommodityPackingratio/querySellStock",
    method: "get",
    params,
  });
}

// 我的客户
export function myCustomer(params) {
  return request({
    url: "/salesManCenter/myCustomer",
    method: "get",
    params,
  });
}

export function getDcShoppingmallStore(params) {
  return request({
    url: "dcShoppingmallStore",
    method: "get",
    params,
  });
}

export function addDcShoppingmallStore(data) {
  return request({
    url: "dcShoppingmallStore",
    method: "post",
    data,
  });
}

export function editDcShoppingmallStore(data) {
  return request({
    url: "dcShoppingmallStore",
    method: "put",
    data,
  });
}

export function deteleDcShoppingmallStore(id) {
  return request({
    url: "dcShoppingmallStore/" + id,
    method: "delete",
  });
}

export function addDcShoppingmallStoreImage(data) {
  return request({
    url: "/dcShoppingmallStore/addImage",
    method: "post",
    data,
  });
}

export function deteleDcShoppingmallStoreImage(id) {
  return request({
    url: "dcShoppingmallStore/deleteImage/" + id,
    method: "delete",
  });
}

// 新付款提醒
export function queryNewPayment(params) {
  return request({
    url: "/salesManCenter/queryNewPayment",
    method: "get",
    params,
  });
}

// 未付款提醒
export function queryUnpaidRemind(params) {
  return request({
    url: "/salesManCenter/queryUnpaidRemind",
    method: "get",
    params,
  });
}

export function getDcShoppingmallStoreIntroduction(params) {
  return request({
    url: "dcShoppingmallIntroduction",
    method: "get",
    params,
  });
}

export function editDcShoppingmallStoreIntroduction(data) {
  return request({
    url: "dcShoppingmallIntroduction",
    method: "put",
    data,
  });
}

export function addDcShoppingmallStoreIntroduction(params) {
  return request({
    url: "dcShoppingmallIntroduction",
    method: "post",
    params,
  });
}

export function getDcShoppingmallNews(params) {
  return request({
    url: "dcShoppingmallNews",
    method: "get",
    params,
  });
}

// 个人业绩
export function queryPerformance(params) {
  return request({
    url: "/salesManCenter/queryPerformance",
    method: "get",
    params,
  });
}

// 个人业绩详情
export function queryPerformanceDetail(params) {
  return request({
    url: "/salesManCenter/queryPerformanceDetail",
    method: "get",
    params,
  });
}

// 订货提醒
export function orderingRemind(params) {
  return request({
    url: "/salesManCenter/orderingRemind",
    method: "get",
    params,
  });
}

export function editDcShoppingmallNews(data) {
  return request({
    url: "dcShoppingmallNews",
    method: "put",
    data,
  });
}

export function addDcShoppingmallNews(data) {
  return request({
    url: "dcShoppingmallNews",
    method: "post",
    data,
  });
}

export function deteleDcShoppingmallNews(id) {
  return request({
    url: "dcShoppingmallNews/" + id,
    method: "delete",
  });
}

export function addDcShoppingmallNewsAddImage(data) {
  return request({
    url: "dcShoppingmallNews/addImage",
    method: "post",
    data,
  });
}

export function addDcShoppingmallIntroductionAddImage(data) {
  return request({
    url: "dcShoppingmallIntroduction/addImage",
    method: "post",
    data,
  });
}

export function deteleShoppingmallNewsAddImage(id) {
  return request({
    url: "dcShoppingmallNews/deleteImage/" + id,
    method: "delete",
  });
}
export function deteleShoppingmallNewsAddImage1(id) {
  return request({
    url: "dcMarketingcenterNotice/deleteImage/" + id,
    method: "delete",
  });
}

// 客户排行
export function customerRank(params) {
  return request({
    url: "salesManCenter/customerRank",
    method: "get",
    params,
  });
}

// 客户排行详情
export function customerRankDetail(params) {
  return request({
    url: "salesManCenter/customerRankDetail",
    method: "get",
    params,
  });
}

// 根据类别查询商品
export function queryCommodityByCategory(params) {
  return request({
    url: "dcBasearchiveCommodity/queryCommodityByCategory",
    method: "get",
    params,
  });
}

// 修改商品排序
export function changCommoditySort(data) {
  return request({
    url: "dcBasearchiveCommodity/changCommoditySort",
    method: "post",
    data,
  });
}

export function addDcShoppingmallNewsAddImage1(data) {
  return request({
    url: "dcMarketingcenterNotice/addImage",
    method: "post",
    data,
  });
}
export function getQueryRepeatOrder(params) {
  return request({
    url: "dcOrdercenterOrder/queryRepeatOrderBak",
    method: "get",
    params,
  });
}
export function repeatOrderRepeatOrderAgree(data) {
  return request({
    url: "dcOrdercenterOrder/repeatOrderAgree",
    method: "put",
    data,
  });
}
export function repeatOrderRepeatOrderDisagree(data) {
  return request({
    url: "dcOrdercenterOrder/repeatOrderDisagree",
    method: "put",
    data,
  });
}

// 查询关联商品
export function dcBasearchiveCommodityRelate(params) {
  return request({
    url: "dcBasearchiveCommodityRelate",
    method: "get",
    params,
  });
}

// 修改关联商品
export function EditdcBasearchiveCommodityRelate(data) {
  return request({
    url: "dcBasearchiveCommodityRelate",
    method: "put",
    data,
  });
}
export function addDcShoppingmallIntroductionAddImageAndVideo(data) {
  return request({
    url: "dcBasearchiveMenuhelp/addFile",
    method: "post",
    data,
  });
}
export function editDcBasearchiveMenuhelpService(data) {
  return request({
    url: "dcBasearchiveMenuhelp",
    method: "put",
    data,
  });
}
export function dcChatcenterGroupchatmember(data) {
  return request({
    url: "dcChatcenterGroupchatmember",
    method: "put",
    data,
  });
}
export function editGroupChat(data) {
  return request({
    url: "dcChatcenterGroupchat/editGroupChat",
    method: "put",
    data,
  });
}
export function getDcBasearchiveMenuhelpService(params) {
  return request({
    url: "dcBasearchiveMenuhelp/queryList",
    method: "get",
    params,
  });
}
export function getQueryOrderCompany(params) {
  return request({
    url: "dcOrdercenterOrder/queryOrderCompany",
    method: "get",
    params,
  });
}
export function getQueryOrderDepartment(params) {
  return request({
    url: "dcOrdercenterOrder/queryOrderDepartment",
    method: "get",
    params,
  });
}
export function deteleDcOrdercenterReceipt(id) {
  return request({
    url: "dcOrdercenterReceipt/" + id,
    method: "delete",
  });
}
export function getDcBasearchivePerson(params) {
  return request({
    url: "dcBasearchivePerson",
    method: "get",
    params,
  });
}
export function sysUser(params) {
  return request({
    url: "sysUser",
    method: "get",
    params,
  });
}
export function getQueryCustomerInfo(params) {
  return request({
    url: "/dcCustomercenterCustomer/queryCustomerInfo",
    method: "get",
    params,
  });
}
export function userQueryInternalNotice(params) {
  return request({
    url: "/dcMarketingcenterNotice/queryInternalNotice",
    method: "get",
    params,
  });
}
export function queryChatCustomer(params) {
  return request({
    url: "/chatCenter/customerQueryGroupChat ",
    method: "get",
    params,
  });
}
export function dchatCenterQueryUsers(params) {
  return request({
    url: "/chatCenter/queryUsers",
    method: "get",
    params,
  });
}
export function chatCenterQueryChatToSbHistory(params) {
  return request({
    url: "/chatCenter/queryChatToSbHistory",
    method: "get",
    params,
  });
}
export function queryGroupchatMember(params) {
  return request({
    url: "/dcChatcenterGroupchat/queryGroupchatByUser",
    method: "get",
    params,
  });
}
export function queryGroupchatMsg(params) {
  return request({
    url: "/dcChatcenterGroupchat/queryGroupchatMsg",
    method: "get",
    params,
  });
}
export function queryGroupchatMemberQL(params) {
  return request({
    url: "/dcChatcenterGroupchat/queryGroupchatMember",
    method: "get",
    params,
  });
}
export function dcChatcenterGroupchatmemberAdd(data) {
  return request({
    url: "dcChatcenterGroupchatmember",
    method: "post",
    data,
  });
}


export function dcChatcenterGroupchatmemberdelete(data) {
  return request({
    url: "dcChatcenterGroupchatmember",
    method: "delete",
    data
  });
}
export function changeHeadImageUrl(data) {
  return request({
    url: "sysUser/changeHeadImageUrl",
    method: "post",
    data,
  });
}
export function getProductingCommodityPageByPlanId(params) {
  return request({
    url: "/shoppingMallCommodity/getProductingCommodityPageByPlanId",
    method: "get",
    params,
  });
}





// export function dcMarketingcenterNotice(params) {
//   return request({
//     url: "/dcMarketingcenterNotice",
//     method: "get",
//     params,
//   });
// }
// dcBasearchiveCommodity/export1 